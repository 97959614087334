<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input allowClear v-model="params.userName" size='small' style="width:150px;" placeholder='请输入昵称'></a-input>
        <a-select allowClear v-model="params.status" size='small' style="width:150px;margin-left:10px;" placeholder='申请状态'>
          <a-select-option :value='0'>申请中</a-select-option>
          <a-select-option :value='1'>通过</a-select-option>
          <a-select-option :value='2'>拒绝</a-select-option>
          <a-select-option :value='9'>结束</a-select-option>
        </a-select>
        <a-select allowClear v-model="params.withdrawalType" size='small' style="width:150px;margin-left:10px;" placeholder='申请状态'>
          <a-select-option value='order'>货款提现</a-select-option>
          <a-select-option value='account'>钱包余额提现</a-select-option>
        </a-select>
        <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
      </div>
    </div>
    <div class="table-content" >
      <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
<!--    点击用户id    -->
        <div slot="userIdSlot" slot-scope="row">
          <span class="cur-pot color-blue" @click="handleSearchUserId(row.userId)">{{  row.userId  }}</span>
        </div>
        <div slot="drawTypeSlot" slot-scope="row">
          <div v-if="row.orderId">
            <div class="color-blue" v-if="row.orderId.indexOf(',') > 0">货款批量提现</div>
            <div class="color-red" v-else>货款单笔提现</div>
          </div>
          <div v-else class="color-green">余额提现</div>
        </div>
<!--    提现类型    typeName-->
        <div slot="drawTypeDescSlot" slot-scope="row">
          <div>{{ row.typeName }}</div>
          <div
              v-if="row.lianlianAccountType"
              :class="row.lianlianAccountType === 'ANONYMOUS' ? 'color-blue' : 'color-green'"
          >{{ row.lianlianAccountType === 'ANONYMOUS' ? '外部提现' : '内部提现' }}</div>
        </div>
        <div slot="set" slot-scope="row">
          <div v-if="row.status!=1 && row.status!=9 && row.status !== 2">
            <a-button
                size="small"
                type="primary"
                class="setup-btn"
                @click="openModal(row,1)"
            >通过</a-button>
          </div>
          <div v-if="row.status!=1 && row.status!=9 && row.status !== 2">
            <a-button
                size="small"
                type="danger"
                class="setup-btn mt-10"
                @click="openModal(row,2)"
            >拒绝</a-button>
          </div>
          <div v-if="row.status ==9">
            <a-button
                size="small"
                class="setup-btn mt-10"
                @click="withDrawTransferStatus(row.id)"
            >同步状态</a-button>
          </div>
        </div>
        <div slot="drawLineSlot" slot-scope="row">
          <a @click="handleShowDrawLine(row)" style='margin-left:5px'>明细</a>
        </div>
      </a-table>
    </div>
    <a-modal v-model="visible" :title='proxyObj.status == 1 ? "通过" : "拒绝"' @cancel='close' @ok='confirmMethod'>
      <a-textarea v-model="proxyObj.reviewMessage" placeholder='请输入备注'></a-textarea>
    </a-modal>
    <DrawLine ref="drawLinePopup"/>
  </div>
</template>

<script>
import DrawLine from "@/views/cmsPage/dqPlatform/components/DrawLine";
export default {
  components: {DrawLine},
  data(){
    return {
      columns:[
        {title:'ID',dataIndex:'id'},
        {title:'昵称',dataIndex:'userName'},
        {title:'userId', scopedSlots: { customRender: 'userIdSlot' }},
        {title:'提现金额',dataIndex:'money'},
        {title:'到账金额',dataIndex:'receiveMoney'},
        {title:'申请时间',dataIndex:'createTime'},
        {title:'提现类型',align:'center',scopedSlots: { customRender: 'drawTypeSlot' }, width: 150},
        {title:'提现方式',align:'center', scopedSlots: { customRender: 'drawTypeDescSlot' }, width: 150},
        {title:'审核时间',align:'center',dataIndex:'reviewTime'},
        {title:'审核状态',align:'center',dataIndex:'statusName'},
        {title:'明细查看',align:'center',scopedSlots: { customRender: 'drawLineSlot' },},
        {title:'备注',align:'center',dataIndex:'reviewMessage'},
        {title:'操作',align:'center',scopedSlots: { customRender: 'set' },},
      ],
      data:[],
      visible:false,
      params:{pageNum:1,pageSize:10,userName:'',userPhone:'', withdrawalType: undefined},
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      loading:false,
      proxyObj:{},
    }
  },
  created(){
    const { userName } = this.$route.query;
    this.$set(this.params, "userName", userName);
    this.getData(this.params)
  },
  methods:{
    /** 点击搜索用户id */
    handleSearchUserId(id) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/withdrawalmannage?userName=${id}`
      window.open(url)
    },
    /** 显示提现明细 */
    handleShowDrawLine(row) {
      let title =''
      if (row.orderId) {
        if (row.orderId.indexOf(',') > 0) {
          title = '货款批量'
        } else {
          title = '货款单笔'
        }
      } else {
        if (row.lianlianAccountType) {
          if (row.lianlianAccountType === 'ANONYMOUS') {
            title = '连连余额【外部提现】---'
          } else {
            title = '连连余额【内部提现】---'
          }
        } else {
          title = '余额'
        }

      }
      this.$refs.drawLinePopup.show(row.id, title, row.orderId, row)
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/userWithdrawDetail/getWithdrawList',{params:params})
      this.loading = false
      const {records,total} = res.data
      this.data = records
      this.pagination.total = total
    },
    search(){
      this.getData(this.params)
    },

    changePage(page){
      const {current,pageSize} = page
      this.pagination.current = current
      this.params.pageNum = current
      this.params.limit = pageSize
      this.getData(this.params)
    },
    async confirmMethod(){
      this.$loading.show();
      const res = await this.axios.post('/dq_admin/userWithdrawDetail/doWithdraw',this.proxyObj)
      this.$loading.hide();
      const {status} = res||{status:500}
      if(status == 200){
        const {message} = res
        this.$message.success(message)
        this.getData(this.params)
        this.close()
      }
    },
    openModal(row,status){
      this.visible = true
      const {id,reviewMessage} = row
      const params = {status,reviewMessage,id}
      this.proxyObj = params
    },
    async withDrawTransferStatus(id){
      const res = await this.axios('/dq_admin/userWithdrawDetail/asyncWithDrawStatus',{params: { id: id }})
      const {status} = res||{status:500}
      if(status == 200){
        const {message} = res
        this.$message.success(message)
        this.getData(this.params)
        this.close()
      }
    },
    close(){
      this.proxyObj = {}
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;max-height:700px;}
.color-red {
  color: red;
}
.color-green {
  color: green;
}
.color-blue {
  color: #2f54eb;
}
.mt-10 {
  margin-top: 10px;
}
</style>